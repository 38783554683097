import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "plataformas", "listarGestor"),
  listadoBasico: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "plataformas", "listadoBasico"),

  // POST
  agregar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "plataformas", "agregar"),
  editar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "plataformas", "editar"),
  eliminar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "plataformas", "eliminar"),
};
