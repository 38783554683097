
import PlataformaService from "../../../Services/PlataformaService";
import CampaniaService from "../../../Services/CampaniaService";
import CampaniaAgregarModal from "./componentes/CampaniaAgregarModal.vue";

export default {
  name: "campania-gestor",
  components: { CampaniaAgregarModal },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Configuración" }, { etiqueta: "Campañas" }],
      plataformaOpciones: [],
      statusOpciones: [
        this.constantesStatusCadena.campaniaPendiente,
        this.constantesStatusCadena.campaniaActiva,
        this.constantesStatusCadena.campaniaDescartada,
        this.constantesStatusCadena.campaniaFinalizada,
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      campanias: [],
      columnas: [
        {
          name: "folio",
          header: "#Campaña",
          align: "center",
          field: "folio_campania",
          type: "string",
          minWidth: 90,
          width: 115,
        },
        {
          name: "titulo",
          header: "Título",
          align: "left",
          field: "titulo",
          minWidth: 140,
          width: 220,
        },
        {
          name: "plataforma",
          header: "Plataforma",
          align: "left",
          field: "plataforma_titulo",
          minWidth: 140,
          width: 170,
        },
        {
          name: "periodo",
          header: "Período",
          align: "left",
          field: "fecha_inicio",
          minWidth: 120,
          width: 140,
          formatear: (val, row) =>
            `${this.formatearFecha(val, 2)} - ${this.formatearFecha(
              row.fecha_fin,
              2
            )}`,
        },
        {
          name: "inversion",
          header: "Inversión",
          align: "right",
          field: "inversion",
          type: "number",
          minWidth: 100,
          width: 110,
          formatear: (val) => this.accounting.formatMoney(val),
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 100,
          width: 120,
          templateName: "statusCadena",
          statusName: "status",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 110,
          width: 130,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 140,
          width: 160,
        },
        {
          name: "opciones",
          header: "",
          align: "center",
          minWidth: 60,
          width: 60,
          columnChooser: false,
          templateName: "opciones",
          templateRestriccionCampo: "status",
          templateRestriccionValores: [
            this.constantesStatusCadena.campaniaDescartada,
          ],
          templateOpciones: [{ accion: "editar", icono: "editar" }],
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      modalEditar: false,
      editarCampaniaObj: {},
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {
    fechaInicioCapturadaEdicion() {
      return this.editarCampaniaObj.fecha_inicio != "";
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerPlataformas(false);
      await this.obtenerCampanias(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s), // El segundo parametro es para arrays con valores numericos
        ordenar: "registro_fecha_desc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerCampanias(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await CampaniaService.listarGestor(this.filtros)
        .then((resp) => {
          let campanias = resp;
          this.campanias = campanias.map((c) => {
            return {
              ...c,
              registro_fecha: new Date(c.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerPlataformas(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        status: [200],
        ordenar: "titulo_asc",
      };
      await PlataformaService.listadoBasico(filtros)
        .then((resp) => {
          this.plataformaOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarCampania(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        campaniaId: this.editarCampaniaObj.campania_id,
        titulo: this.editarCampaniaObj.titulo,
        plataformaId: this.editarCampaniaObj.plataforma_id,
        inversion: this.editarCampaniaObj.inversion,
        fechaInicio: this.dayjs(
          this.editarCampaniaObj.fecha_inicio,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        fechaFin: "",
        status: this.editarCampaniaObj.status,
      };

      if (this.editarCampaniaObj.fecha_fin) {
        datos.fechaFin = this.dayjs(
          this.editarCampaniaObj.fecha_fin,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
      await CampaniaService.editar(datos)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerCampanias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
        ordenar: "registro_fecha_desc",
      };
      this.obtenerCampanias();
      this.accionLimpiar = true;
    },
    manejarClickOpcion(campania, accion) {
      if (accion == "editar") this.abrirModalEditar(campania);
    },
    abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.modalAgregar = false;
    },
    abrirModalEditar(campania) {
      this.editarCampaniaObj = { ...campania };
      // Se formatean fechas para edicion
      let fechaInicio = this.dayjs(this.editarCampaniaObj.fecha_inicio).format(
        "YYYY-MM-DD"
      );
      let fechaActual = this.dayjs().format("YYYY-MM-DD");
      if (this.dayjs(fechaInicio).isBefore(fechaActual)) {
        this.editarCampaniaObj.fecha_inicio_original = fechaInicio;
      } else this.editarCampaniaObj.fecha_inicio_original = fechaActual;

      this.editarCampaniaObj.fecha_inicio = this.dayjs(
        this.editarCampaniaObj.fecha_inicio
      ).format("DD/MM/YYYY");

      if (this.editarCampaniaObj.fecha_fin) {
        this.editarCampaniaObj.fecha_fin = this.dayjs(
          this.editarCampaniaObj.fecha_fin
        ).format("DD/MM/YYYY");
      }
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarCampaniaObj = {};
      this.modalEditar = false;
    },
    validarFechaFin(val, fechaInicio) {
      if (this._.isEmpty(val)) return true;
      return this.validarFecha(
        val,
        1,
        false,
        false,
        true,
        2019,
        this.dayjs(fechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
    },
  },
};
