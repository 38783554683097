import RepoUtils from "./Request/RepoUtils";

export default {
  // // GET
  // listarGestor: (filtros) =>
  //   RepoUtils.ejecutarPeticionGet(filtros, "campanias", "/"),

  // // POST
  // agregar: (data) => RepoUtils.ejecutarPeticionPostV2(data, "campanias"),
  // editar: (data) =>
  //   RepoUtils.ejecutarPeticionPostV2(data, "campanias", data.campaniaId),
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "campanias", "listarGestor"),

  // POST
  agregar: (data) => RepoUtils.ejecutarPeticionPostV2(data, "campanias"),
  editar: (data) =>
    RepoUtils.ejecutarPeticionPostV2(data, "campanias", data.campaniaId),
};
