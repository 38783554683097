import { render, staticRenderFns } from "./CampaniaAgregarModal.vue?vue&type=template&id=bef1ee06&"
import script from "./CampaniaAgregarModal.vue?vue&type=script&lang=js&"
export * from "./CampaniaAgregarModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QForm,QInput,QSelect,QIcon,QBtn,QPopupProxy,QDate} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QInput,QSelect,QIcon,QBtn,QPopupProxy,QDate})
