
import CampaniaService from "../../../../Services/CampaniaService";

export default {
  name: "campania-agregar-modal",
  props: {
    modelo: {
      type: Boolean,
      required: true,
    },
    plataformaOpciones: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agregarCampaniaObj: {
        titulo: "",
        plataformaId: "",
        inversion: "",
        fechaInicio: "",
        fechaFin: "",
      },
    };
  },
  computed: {
    fechaInicioCapturada() {
      return this.agregarCampaniaObj.fechaInicio != "";
    },
  },
  watch: {
    "agregarCampaniaObj.fechaInicio"(val) {
      if (val == "") this.agregarCampaniaObj.fechaFin = "";
    },
  },
  mounted() {},
  methods: {
    cerrarModalAgregar() {
      this.agregarCampaniaObj = {
        titulo: "",
        plataformaId: "",
        inversion: "",
        fechaInicio: "",
        fechaFin: "",
      };
      this.$emit("cerrar-modal-agregar");
    },
    async agregarCampania(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.agregarCampaniaObj,
        fechaInicio: this.dayjs(
          this.agregarCampaniaObj.fechaInicio,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };

      if (this.agregarCampaniaObj.fechaFin != "") {
        datos.fechaFin = this.dayjs(
          this.agregarCampaniaObj.fechaFin,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
      await CampaniaService.agregar(datos)
        .then(async (resp) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(resp.mensaje, "exito");
          this.$emit("exito");
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        })
        .then(() => {});
    },
    validarFechaFin(val, fechaInicio) {
      if (this._.isEmpty(val)) return true;
      return this.validarFecha(
        val,
        1,
        false,
        false,
        true,
        2019,
        this.dayjs(fechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
    },
  },
};
